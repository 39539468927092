<template>
  <div class="pop-backdrop">
    <div class="modal-backdrop" @click="closeSelf">
      <div class="modal" v-for="(item, index) in pralist" :key="index">
        <div v-if="id === item.id" class="modal-box">
          <div class="modal-header">
            <h3>{{ $t('COFITcompany.' + item.cnname) }}</h3>
          </div>
          <div class="modal-body">
            <iframe
              name="iframeName"
              ref="iframe"
              id="iframe"
              class="video"
              :src="item.video"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn-close" @click="closeSelf">
              {{ $t('COFITcompany.close') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Modal',
  props: ['pralist', 'id'],
  data () {
    return {
      colseiframe: true
    }
  },
  mounted () {

  },
  methods: {

    closeSelf () {
      this.$emit("closeme", "colseiframe");
      // this.colseiframe=false
    }
  }
}
</script>
<style lang="scss" scoped>
.pop-backdrop {
  width: 100%;
  .modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
  }
  .modal {
    margin: auto;
    margin-top: 50px;
    background-color: rgb(241, 239, 239);
    // box-shadow: 2px 2px 20px 1px;
    position: absolute;
    display: flex;
    // flex-direction: column;
    border-radius: 16px;
    margin-left: 15%;
    width: 70%;
    .modal-box {
      width: 90%;
      margin-left: 5%;
    }
  }
  .modal-header {
    border-bottom: 1px solid #eee;
    color: #313131;
    justify-content: space-between;
    padding: 15px 0;
    display: flex;
  }
  .modal-footer {
    border-top: 1px solid #eee;
    justify-content: flex-end;
    padding: 15px;
    display: flex;
    .btn-close {
      border: 1px solid rgb(190, 192, 190);
    }
  }
  .modal-body {
    position: relative;
    width: 100%;
  }
  .btn-close {
    border-radius: 8px;
    margin-left: 16px;
    width: 56px;
    height: 36px;
    border: none;
    cursor: pointer;
    color: #313131;
    background: transparent;
    font-weight: 600;
  }
  .video {
    border-radius: 5px;
    width: 100%;
    height: 400px;
  }
}
@media screen and (max-width: 1000px) {
  .pop-backdrop {
    .modal {
      margin: auto;
      margin-top: 100px;
      position: absolute;
      display: flex;
      border-radius: 16px;
      margin-left: 15%;
      width: 70%;
      .modal-box {
        width: 90%;
        margin-left: 5%;
        // margin: 0 5px;
      }
    }
    .video {
      width: 100%;
      height: 350px;
    }
  }
}
@media screen and (max-width: 650px) {
  .pop-backdrop {
    .modal {
      margin-left: 10%;
      width: 80%;
    }
    .video {
      width: 100%;
      height: 270px;
    }
  }
}
@media screen and (max-width: 520px) {
  .pop-backdrop {
    .modal {
      margin-top: 100px;
      margin-left: 2.5%;
      width: 95%;
      .modal-box {
        width: 100%;
        margin-left: 0;
        margin: 0 5px;
      }
    }
    .modal-header {
      padding: 15px 15px 0;
    }
    .modal-body {
      padding: 20px 0;
      width: 100%;
    }
    .video {
      width: 100%;
      height: 195px;
    }
  }
}
</style>